<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <app-card
                        :fullBlock="true"
                        colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation>
                        <v-expansion-panels
                                accordion
                                v-model="panel"
                                multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.filterCommissions') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 md2 pl-5 py-1 class="red--text">{{ $t('message.from') }}*</v-flex>
                                        <v-flex xs6 md3 py-1>
                                        <v-select
                                                v-model="contract.years"
                                                solo
                                                dense
                                                required
                                                :rules="[v => !!v || '']"
                                                item-text="year"
                                                item-value="id"
                                                :label="contract.years ? '' : $t('message.year')"
                                                hide-details
                                                clearable
                                        ></v-select>
                                        <v-select
                                                v-model="contract.month"
                                                solo
                                                dense
                                                required
                                                :rules="[v => !!v || '']"
                                                item-text="month"
                                                item-value="id"
                                                :label="contract.month ? '' : $t('message.month')"
                                                hide-details
                                                clearable
                                        ></v-select>
                                        <center>to</center>
                                        <v-select
                                                v-model="contract.month"
                                                solo
                                                dense
                                                required
                                                :rules="[v => !!v || '']"
                                                item-text="month"
                                                item-value="id"
                                                :label="contract.month ? '' : $t('message.month')"
                                                hide-details
                                                clearable
                                        ></v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1 class="red--text">{{ $t('message.marketingActive') }}*</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-select
                                                    v-model="contract.marketing"
                                                    solo
                                                    dense
                                                    :rules="[v => !!v || '']"
                                                    required
                                                    item-text="title"
                                                    item-value="id"
                                                    :label="contract.marketing ? '' : $t('message.required')"
                                                    hide-details
                                                    clearable
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex d-flex xs12>
                                            <v-layout row px-2 pt-3>
                                               <v-btn
                                                       color="info"
                                                       small
                                                       class="ml-3 mt-1 info lighten-2"
                                                >{{ $t('message.exportToExcel') }}
                                               </v-btn>
                                                <v-btn
                                                        color="info"
                                                        small
                                                        class="ml-3 mt-1 info lighten-2"
                                                >{{ $t('message.exportToPdf') }}
                                                </v-btn>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
                v-model="previewDialog"
                transition="dialog-bottom-transition"
                overlay=false
                scrollable
        >
        </v-dialog>
    </div>
</template>

<script>
    import { api } from "../../../api";
    import {textTruncate, getCurrentAppLayout} from "Helpers/helpers"
    import FileUploadList from "../../../components/Appic/FileUploadList";
    import {mapGetters} from "vuex";
    import {mapActions} from "vuex";

    export default {
        name: "NewContract",
        components: {FileUploadList},
        data() {
            return {
                loader: true,
                bank_account: {},
                openSection: false,
                previewDialog: false,
                panel: [0],
                contract: {
                    office_id: 1,
                    salestype_id: 1,
                    currency_id: 2,
                    issigned: 0,
                    isposigned: 0,
                    showbank: 1
                },
                years: [
                    {value: 2017, text: "2017"},
                    {value: 2018, text: "2018"},
                    {value: 2019, text: "2019"},
                    {value: 2020, text: "2020"}
                ],
                months: [
                    {value: 1, text: "Jan"},
                    {value: 2, text: "Feb"},
                    {value: 3, text: "Mar"},
                    {value: 4, text: "Apr"},
                    {value: 5, text: "May"},
                    {value: 6, text: "Jun"},
                    {value: 7, text: "Jul"},
                    {value: 8, text: "Aug"},
                    {value: 9, text: "Sep"},
                    {value: 10, text: "Oct"},
                    {value: 11, text: "Nov"},
                    {value: 12, text: "Dec"},
                ],
                marketing:[],


            }
        },
        computed: {
          ...mapGetters(),
          ...mapGetters([]),
        },
        mounted(){
            this.getOffices();
            this.getSalesTypes();
            this.getBuyers();
            this.getSuppliers();
            this.getCurrencies();
            this.getProductGroups();
            this.getPaymentTerms();
        },
        methods: {
          ...mapActions(),
            // getOffices() {
            //     api
            //         .get("appic/getoffices")
            //         .then(response => {
            //             this.loader = false;
            //             this.offices = response.data;
            //         })
            //         .catch(error => {
            //             console.log(error);
            //         });
            // },
            // getSalesTypes() {
            //     api
            //         .get("appic/getsalestypes")
            //         .then(response => {
            //             this.loader = false;
            //             this.salestypes = response.data;
            //         })
            //         .catch(error => {
            //             console.log(error);
            //         });
            // },
            // getBuyers() {
            //     api
            //         .get("appic/getcustomers")
            //         .then(response => {
            //             this.loader = false;
            //             this.buyers = response.data;
            //         })
            //         .catch(error => {
            //             console.log(error);
            //         });
            // },
            // getSuppliers() {
            //     api
            //         .get("appic/getvendors")
            //         .then(response => {
            //             this.loader = false;
            //             this.suppliers = response.data;
            //         })
            //         .catch(error => {
            //             console.log(error);
            //         });
            // },
            // getCurrencies() {
            //     api
            //         .get("appic/getcurrencies")
            //         .then(response => {
            //             this.loader = false;
            //             this.currencies = response.data;
            //         })
            //         .catch(error => {
            //             console.log(error);
            //         });
            // },
            // getProductGroups() {
            //     api
            //         .get("appic/getproductgroups")
            //         .then(response => {
            //             this.loader = false;
            //             this.product_groups = response.data;
            //         })
            //         .catch(error => {
            //             console.log(error);
            //         });
            // },
            // getPaymentTerms() {
            //     api
            //         .get("appic/getpaymentterms")
            //         .then(response => {
            //             this.loader = false;
            //             this.payment_statements.buyer = response.data.buyer;
            //             this.payment_statements.supplier = response.data.supplier;
            //         })
            //         .catch(error => {
            //             console.log(error);
            //         });
            // },
        }
    }
</script>

<style scoped>

</style>
